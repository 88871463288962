<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Ajouter une magasin
              <router-link to="/getmagasins">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="addmagasin()">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Nom <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="magasin.nom"
                        id="first"
                        required
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Adresse <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="first"
                        v-model="magasin.adresse"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >categorie <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        v-model="magasin.categorie"
                        placeholder=""
                        id="first"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >capacite <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="number"
                        :min="1"
                        class="form-control"
                        v-model="magasin.capacite"
                        placeholder=""
                        id="first"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Emplacements
                        <span class="obligatoire">*</span>
                      </label>

                      <v-select
                        :multiple="true"
                        :closeOnClick="false"
                        v-model="magasin.emplacements"
                        :options="emplacements"
                        :reduce="(emplacements) => emplacements"
                        label="code"
                      />
                    </div>
                    <span class="text-danger" id="emplac_valid" style="display:none">Il faut choisir les emplacements</span>

                  </div>

                  <!--  col-md-6   -->
                  <div class="col-md-4">
                    <br />
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary">
                        + Ajouter magasin
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";

import moment from "moment";
export default {
  data() {
    return {
      magasin: {
        nom: "",
        categorie: "",
        adresse: "",
        capacite: "",
        emplacements: [],
      },
      emplacements: [],
      users: [],
      error: "",
    };
  },

  created() {
    this.getemplacements();
  },
  methods: {
    getemplacements() {
      HTTP.get("emplacements/getemplacements")
        .then((response) => {
          this.emplacements = response.data;
        })
        .then((err) => console.log(err));
    },

    addmagasin() {


    if(this.magasin.emplacements.length==0){
      $('#emplac_valid').show()

setTimeout(function() {
     $('#emplac_valid').fadeOut('fast');
}, 5000); 


    }






   else if(this.magasin.emplacements.length>0){
   

      this.magasin.emplacements.forEach((emplac) => {
        emplac.emplacement_id = emplac._id;
        delete emplac.__v;
        delete emplac._id;
        delete emplac.code;
        delete emplac.capacite;
        delete emplac.quantite_existante;
        delete emplac.capacite_vide;
        delete emplac.created_at;
        delete emplac.updated_at;
      });

      HTTP.post("magasins/addmagasin", this.magasin).then(
        (response) => {
          console.log(response);
          Toast.fire({
            icon: "success",
            title: "Magasin ajouté",
          });

          this.$router.push("/getmagasins");
        },
        (err) => {
          console.log(err);
        }
      );
    }
    },
  },
};
</script>
